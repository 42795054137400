import React from 'react';
import PropTypes from 'prop-types';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import { IconButton, Input, Tooltip } from '@material-ui/core';
import {
  AiOutlineInfoCircle,
  AiOutlineMinusCircle,
  AiOutlinePlusCircle
} from 'react-icons/ai';
import { useHandleResize } from '../../utils';

const VisitorsManager = ({
  t,
  purpose,
  state,
  dispatch,
  addNbVisitors,
  subtractNbVisitors,
  openModalLimitVisitors
}) => {
  const { width } = useHandleResize();

  const onHandleClose = () => {
    switch (purpose) {
      case 'reduced_price':
        dispatch({ openReducedPrice: false });
        break;
      case 'child_price':
        dispatch({ openChildPrice: false });
        break;
      case 'free_price':
        dispatch({ openFreePrice: false });
        break;
    }
  };

  const onHandleOpen = () => {
    switch (purpose) {
      case 'reduced_price':
        dispatch({ openReducedPrice: true });
        break;
      case 'child_price':
        dispatch({ openChildPrice: true });
        break;
      case 'free_price':
        dispatch({ openFreePrice: true });
        break;
    }
  };

  const valueReturn = () => {
    switch (purpose) {
      case 'reduced_price':
        return state.openReducedPrice;
      case 'child_price':
        return state.openChildPrice;
      case 'free_price':
        return state.openFreePrice;
      default:
        return false;
    }
  };

  const onHandleInputChange = (value) => {
    let total = 0;
    switch (purpose) {
      case 'full_price':
        total =
          value + state.reduced_price + state.child_price + state.free_price;
        break;
      case 'reduced_price':
        total = value + state.full_price + state.child_price + state.free_price;
        break;
      case 'child_price':
        total =
          value + state.full_price + state.reduced_price + state.free_price;
        break;
      case 'free_price':
        total =
          value + state.full_price + state.reduced_price + state.child_price;
        break;
    }

    if (total < 20) {
      switch (purpose) {
        case 'full_price':
          dispatch({ full_price: value, totalVisitor: total });
          break;
        case 'reduced_price':
          dispatch({ reduced_price: value, totalVisitor: total });
          break;
        case 'child_price':
          dispatch({ child_price: value, totalVisitor: total });
          break;
        case 'free_price':
          dispatch({ free_price: value, totalVisitor: total });
          break;
      }
    } else {
      openModalLimitVisitors();
    }
  };

  const returnInputValue = () => {
    let value = 0;
    switch (purpose) {
      case 'full_price':
        value = state.full_price;
        break;
      case 'reduced_price':
        value = state.reduced_price;
        break;
      case 'child_price':
        value = state.child_price;
        break;
      case 'free_price':
        value = state.free_price;
        break;
    }
    return value;
  };

  const onHandleSubtractButtonDisable = () => {
    let value = false;
    switch (purpose) {
      case 'full_price':
        value = state.full_price === 0;
        break;
      case 'reduced_price':
        value = state.reduced_price === 0;
        break;
      case 'child_price':
        value = state.child_price === 0;
        break;
      case 'free_price':
        value = state.free_price === 0;
        break;
    }
    return value;
  };

  return (
    <>
      <ClickAwayListener onClickAway={onHandleClose}>
        <Tooltip
          PopperProps={{
            disablePortal: true
          }}
          onMouseEnter={onHandleOpen}
          onClose={onHandleClose}
          open={valueReturn()}
          disableFocusListener
          disableTouchListener
          title={t(`reservationPage.nbPersons.${purpose}_details`)}
          placement="top-start"
        >
          <p
            onClick={onHandleOpen}
            style={{
              marginBottom: 8,
              textTransform: 'uppercase',
              fontWeight: 700
            }}
          >
            {`${t(`reservationPage.nbPersons.${purpose}`)} `}
            {width > 1024 && purpose !== 'full_price' && (
              <AiOutlineInfoCircle />
            )}
          </p>
        </Tooltip>
      </ClickAwayListener>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <IconButton
          style={{
            opacity: onHandleSubtractButtonDisable() ? 0.5 : 1
          }}
          disabled={onHandleSubtractButtonDisable()}
          onClick={() => subtractNbVisitors(purpose)}
        >
          <AiOutlineMinusCircle />
        </IconButton>
        <Input
          style={{ width: 30 }}
          value={returnInputValue()}
          onChange={(event) =>
            onHandleInputChange(parseInt(event.target.value) || 0)
          }
          disableUnderline
        />
        <IconButton
          style={{
            opacity: state.totalVisitor === 19 ? 0.5 : 1
          }}
          onClick={() =>
            state.totalVisitor === 19
              ? openModalLimitVisitors()
              : addNbVisitors(purpose)
          }
        >
          <AiOutlinePlusCircle />
        </IconButton>
      </div>
      <p
        style={{
          fontSize: 14,
          margin: '6px 0 0 50px',
          fontWeight: 300
        }}
      >
        {state.visitType === '17'
          ? t('landingPage.infocard.evening-price')
          : t(`landingPage.infocard.visitor.${purpose}`)}
      </p>
    </>
  );
};

export default VisitorsManager;

VisitorsManager.propTypes = {
  t: PropTypes.func.isRequired,
  purpose: PropTypes.oneOf([
    'full_price',
    'reduced_price',
    'child_price',
    'free_price'
  ]).isRequired,
  state: PropTypes.shape({
    full_price: PropTypes.number,
    reduced_price: PropTypes.number,
    child_price: PropTypes.number,
    free_price: PropTypes.number,
    openReducedPrice: PropTypes.bool,
    openChildPrice: PropTypes.bool,
    openFreePrice: PropTypes.bool,
    totalVisitor: PropTypes.number,
    visitType: PropTypes.string
  }).isRequired,
  dispatch: PropTypes.func.isRequired,
  addNbVisitors: PropTypes.func.isRequired,
  subtractNbVisitors: PropTypes.func.isRequired,
  openModalLimitVisitors: PropTypes.func.isRequired
};
